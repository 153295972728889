<template>
    <div class="container pb-5 mb-5">
        <div class="card" v-if="language">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <h3>{{ language.name }} Labels</h3>
                    <button class="btn btn-primary" @click="saveLanguageValues" :disabled="saving">
                        Save
                        <b-spinner v-if="saving" small label="Spinning" variant="white"></b-spinner>
                    </button>
                </div>
            </div>
            <div class="card-body" style="height:70vh; overflow-y:scroll;">
                <table class="table" v-if="language">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Key</th>
                            <th scope="col">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(label, key) in language.labels" :key="key">
                            <th scope="row">{{ key + 1 }}</th>
                            <td class="" width="500">
                                <input type="text" class="form-control" v-model="label.key">
                            </td>
                            <td>
                                <!-- <textarea name="" id="" cols="30" rows="2" v-model="label.value" class="form-control"></textarea> -->
                                <input type="text" class="form-control" v-model="label.value">
                            </td>
                            <td v-if="key == (language.labels.length - 1)">
                                <button class="btn btn-primary" @click="addKey">Add Key</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Spinner v-else />

    </div>
</template>
  
<script>
import axios from 'axios';
import Spinner from '../../components/ui/Spinner.vue';

export default {
    components: { Spinner },
    data() {
        return {
            loading: false,
            saving: false,
            language: null
        };
    },
    created() {
        console.log("created");
    },
    mounted() {
        console.log("mounted");
        this.getLanguage(this.$route.params.key);
    },
    methods: {
        async getLanguage(key) {
            this.loading = true;
            const { data } = await axios.get(`admin/language/${key}`);
            this.language = data.language;
            this.loading = false;
        },
        addKey() {
            this.language.labels.push({
                key: "",
                value: ""
            });
        },
        async saveLanguageValues() {
            this.saving = true;
            axios.post(`admin/language/${this.language.key}`, { labels: this.language.labels }).then((response) => {
                this.saving = false;
                console.log("response", response)
                this.$bvToast.toast('Language published successfully', {
                    title: `Success`,
                    variant: "success",
                    solid: true
                })
            }).catch((error) => {
                console.log("Error", error);
                this.saving = false;
            });
        }
    },
};
</script>
  